<!--suppress HtmlFormInputWithoutLabel -->
<template>
  <v-container class="form-main">
    <v-row no-gutters>
      <v-col class="form-header text-center">
        <div>Agência de Relacionamento (Definida pelo Cliente)</div>
      </v-col>
    </v-row>
    <v-form>
      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
          UF
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
          <select
            id="cmbAgenciaRelacionamentoUF"
            name="cmbAgenciaRelacionamentoUF"
            style="width:55%"
            v-model="agenciaRelacionamentoUF"
          >
            <option :key="item.sigla" :value="item.sigla" v-for="item in estados">{{ item.sigla }}</option>
          </select>
        </v-col>
      </v-row>
      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
          Município
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
          <select
            id="cmbAgenciaRelacionamentoMunicipio"
            name="cmbAgenciaRelacionamentoMunicipio"
            style="width:55%"
            v-model="agenciaRelacionamentoMunicipio"
          >
            <option :key="item" :value="item" v-for="item in cmbAgenciaRelacionamentoMunicipio">{{ item }}</option>
          </select>
        </v-col>
      </v-row>
      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
          Código e Nome das Agências
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
          <select
            id="cmbCodNomeAgencias"
            name="cmbCodNomeAgencias"
            style="width:95%"
            v-model="agenciaRelacionamentoCodigoNomeAgencia"
          >
            <option :key="item" :value="item" v-for="item in cmbAgencias">{{ item }}</option>
          </select>
        </v-col>
      </v-row>
    </v-form>
    <v-row no-gutters>
      <v-col class="text-center my-3">
        <a @click="salvar" class="btn-azul" id="btn-salvar">Concluir Cadastro</a>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// noinspection ES6CheckImport
import { createNamespacedHelpers, mapActions } from "vuex";
import { mapClienteFields } from "../../../store/modules/caixaqui-clientes";
import ROUTERS from "../../../routes/router-constants";

const { mapGetters: mapDadosGeraisGetters } = createNamespacedHelpers("dadosGerais");

export default {
  name: "CaixaquiCadastroClientesAgenciaRelacionamentoDefinidaCliente",

  data: () => ({
    style: {
      colLeft: 4,
      colRight: 8,
      alignSelf: "stretch",
      colLeftClasses: ["form-col-left", "d-flex", "flex-row", "align-center"],
      colRightClasses: ["form-col-right", "d-flex", "flex-row", "align-center"]
    }
  }),
  computed: {
    ...mapDadosGeraisGetters(["estados", "cidades", "agenciaCaixa"]),
    ...mapClienteFields({
      agenciaRelacionamentoUF: "agenciaRelacionamento.uf",
      agenciaRelacionamentoMunicipio: "agenciaRelacionamento.municipio",
      agenciaRelacionamentoCodigoNomeAgencia: "agenciaRelacionamento.codigoNomeAgencia"
    }),
    cmbAgenciaRelacionamentoMunicipio() {
      return this.cidades(this.agenciaRelacionamentoUF);
    },
    cmbAgencias() {
      return this.agenciaCaixa(this.agenciaRelacionamentoUF, this.agenciaRelacionamentoMunicipio);
    }
  },
  methods: {
    ...mapActions("controller", ["concluirCadastroCliente"]),
    handleForwardEvent(to) {
      // AQUI PODEMOS VALIDAR QUALQUER COISA ANTES DE AVANCAR PARA A PROXIMA PAGINA
      if (to) {
        this.$router.push(to);
      }
    },
    handleBackwardEvent(to) {
      // AQUI PODEMOS VALIDAR QUALQUER COISA ANTES DE VOLTAR PARA A PAGINA
      if (to) {
        this.$router.push(to);
      }
    },
    salvar() {
      this.concluirCadastroCliente().then(() => {
        this.$router.push({ name: ROUTERS.caixaqui.cadastroClientes.resumo.name });
      });
    }
  }
};
</script>

<style scoped src="../../../components/caixaqui/caixaqui-style.css"></style>
<style scoped>
#btn-salvar {
  padding: 3px 12px 3px 12px;
}
</style>
